$box-shadow: rgba(0, 0, 0, 11%) 0px 1px 6px 0px;

.project-cards {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0.5em 1em 0.5em 1em;
  padding: 0.5em 0.5em 0.5em 0.5em;
  box-shadow: #00000069 0px 1px 6px 0px;
  justify-content: space-between;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  z-index: 1;
  height: 400px;
  
  @media (max-width: 768px) {
    height: 350px;
  }
}

.project-cards:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.preview-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  align-self: center;
  
  @media (max-width: 768px) {
    height: 150px;
  }
}

.project-cards > h2 {
  margin: 5px 0 0 0;
  font-size: 20px;
}

.card-footer {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-footer > a {
  margin: 0;
}

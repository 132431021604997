.scripts-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.scripts-modal {
  background-color: #1e1e1e;
  border-radius: 8px;
  width: 90%;
  height: 90vh;
  position: relative;
  overflow: hidden;
  color: #d4d4d4;

  .modal-content {
    display: flex;
    height: 100%;
  }

  .file-explorer {
    width: 250px;
    background-color: #252526;
    overflow-y: auto;
    border-right: 1px solid #3c3c3c;
    padding: 1rem;

    .file-tree-item {
      .file-name, .directory-name {
        padding: 0.2rem;
        cursor: pointer;
        border-radius: 4px;
        margin: 2px 0;
        
        &:hover {
          background-color: #2a2d2e;
        }
      }
    }
  }

  .file-content {
    flex: 1;
    padding: 1rem;
    background-color: #1e1e1e;
    overflow-y: auto;

    pre {
      background-color: #1e1e1e;
      padding: 1rem;
      margin: 0;
      color: #d4d4d4;
      font-family: 'Consolas', 'Monaco', monospace;
      overflow-x: auto;
    }
  }
}

.repo-list-view {
  padding: 2rem;
  
  h2 {
    color: #d4d4d4;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.repo-list {
  max-width: 600px;
  margin: 0 auto;
}

.repo-row {
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #252526;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2a2d2e;
  }

  .repo-icon {
    margin-right: 1rem;
  }
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #d4d4d4;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1;

  &:hover {
    color: #ffffff;
  }
}

.back-button {
  background: none;
  border: none;
  color: #d4d4d4;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  margin-bottom: 1rem;

  &:hover {
    color: #ffffff;
  }
}

.file-header {
  padding-bottom: 1rem;
  border-bottom: 1px solid #3c3c3c;
  margin-bottom: 1rem;

  h3 {
    margin: 0;
    color: #d4d4d4;
  }
}

.no-file-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #6c6c6c;
  font-style: italic;
}

// Scrollbar styling
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #1e1e1e;
}

::-webkit-scrollbar-thumb {
  background: #424242;
  border-radius: 5px;

  &:hover {
    background: #4f4f4f;
  }
}



form{
    padding: 4rem 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: space-around;
}
.contact-form  {
input {
    align-self: center;
    width: 70%;
    box-sizing: border-box;
    border: 2px solid #1351d8;
    padding: 0.8rem 1rem;
    border-radius: 10px;
    margin-bottom: 2.5rem;
    transition: all 0.3s ease 0s;
    background-color: transparent;
    color: unset;
    user-select: none;
    @extend .noSelect;
}
}

button {
    background-color: #1351d8;
    width: 150px;
    color: white;
    text-align: center;
    align-self: left;
    margin: 30px 0px 0px 0px;
    padding: 10px 0px 10px 0px;
    border-radius: 5px;
    border:none;
    @extend .noSelect;
}

textarea {
    border: 2px solid #1351d8;
    border-radius: 10px;
    background-color: transparent;
    color: unset;
    align-self: center;
    resize: none;
    width: 69%;
    height: 100px;
    padding:10px 0px 0px 10px;
    @extend .noSelect;
}

.noSelect {
    outline: none;
}

::placeholder {
    color: unset;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    padding-top: 10vh;
    z-index: 1999;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.5s, opacity 0.5s;
    overflow-y: auto;

    &.show {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }
}

.modal-content {
    background-color: var(--modal-bg, #363537);
    color: var(--modal-text, #FAFAFA);
    padding: 20px;
    border-radius: 12px;
    position: relative;
    width: auto;
    max-width: 90%;
    max-height: 80vh;
    margin-bottom: 10vh;
    overflow-y: auto;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s, opacity 0.5s;
    transform: scale(0.7);
    border: 1px solid rgba(255, 255, 255, 0.1);

    @media (prefers-color-scheme: light) {
        --modal-bg: #FFFFFF;
        --modal-text: #363537;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &.show {
        transform: scale(1);
        opacity: 1;
    }
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-size: large;
    color: inherit;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
        opacity: 1;
    }
}
  
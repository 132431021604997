.blog-container {
  max-width: 720px;
  margin: 0 auto;
  padding: 30px 2rem 8rem 2rem;
  height: calc(100vh - 80px);
  overflow-y: auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  top: 80px;
  scrollbar-gutter: stable;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  animation: fadeIn 0.3s ease-out;
  
  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
  
  &.fade-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  
  &.fade-show {
    opacity: 1;
    transform: translateY(0);
  }
  
  &.fade-exit {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.blog-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  padding-bottom: 4rem;
}

.blog-preview {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease, all 0.3s ease-out;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out forwards;
  
  // Light mode styles
  @media (prefers-color-scheme: light) {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  &:hover {
    transform: translateY(-4px);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    
    @media (prefers-color-scheme: light) {
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }
  }
  
  h2 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    font-weight: 500;
  }
  
  p {
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  &.fade-exit {
    opacity: 0;
    transform: translateY(-20px);
  }
  
  &.fade-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  
  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.back-button {
  background: none;
  border: none;
  color: #1351d8;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  margin-bottom: 2rem;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateX(-4px);
    color: #1351d8;
    text-shadow: 0 0 10px rgba(0, 81, 255, 0.682);
  }
}

.blog-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .blog-header {
    margin-bottom: 3rem;
    
    h1 {
      font-size: 2.5rem;
      font-weight: 500;
    }
  }
  
  .blog-card {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s ease;
    cursor: pointer;
    
    &:hover {
      transform: translateY(-4px);
    }
  }
  
  .blog-card-content {
    padding: 1.5rem;
    
    h2 {
      margin: 0.5rem 0;
      font-size: 1.25rem;
      line-height: 1.4;
    }
    
    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.95rem;
      line-height: 1.5;
      margin-bottom: 1rem;
    }
  }
  
  .blog-date {
    font-size: 0.85rem;
    color: #1351d8;
    text-shadow: 0 0 10px rgba(19, 81, 216, 0.682);
  }
  
  .blog-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
    user-select: none;
    .tag {
      display: inline-block;
      padding: 0.3rem 0.8rem;
      margin: 0.25rem;
      border-radius: 20px;
      background: rgba(19, 81, 216, 0.1);
      color: #1351d8;
      font-size: 0.85rem;
      transition: all 0.2s ease;
      
      &:hover {
        background: rgba(19, 81, 216, 0.2);
        box-shadow: 0 0 12px rgba(19, 81, 216, 0.3);
        transform: translateY(-1px);
      }
    }
  }
  
  @media (max-width: 768px) {
    .blog-container {
      padding: 20px 1rem 2rem 1rem;
      height: calc(100vh - 100px);
      top: 100px;
      position: fixed;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      max-width: 100vw;
      box-sizing: border-box;
    }
    
    .blog-grid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
      margin-top: 0;
      position: relative;
      transform: translateZ(0);
      will-change: transform;
    }

    .blog-preview {
      position: relative;
      transform-style: preserve-3d;
    }
  }

.blog-content {
  line-height: 1.7;
  padding-bottom: 4rem;
  
  h1 {
    font-size: 2.5rem;
    margin: 2rem 0 1rem;
    font-weight: 600;
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
  }
  
  h2 {
    font-size: 1.8rem;
    margin: 2.5rem 0 1rem;
    font-weight: 500;
    text-shadow: 0 0 12px rgba(255, 255, 255, 0.25);
  }
  
  h3 {
    font-size: 1.4rem;
    margin: 2rem 0 1rem;
    font-weight: 500;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
}

@media (max-width: 768px) {
  .blog-container {
    padding: 20px 1rem 2rem 1rem;
    height: calc(100vh - 100px);
    top: 100px;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
  }
  
  .blog-content {
    max-width: 100%;
    padding: 0 0.5rem;
    box-sizing: border-box;
    
    h1 {
      font-size: 1.8rem;
      word-wrap: break-word;
    }
    
    h2 {
      font-size: 1.4rem;
      word-wrap: break-word;
    }
    
    h3 {
      font-size: 1.2rem;
      word-wrap: break-word;
    }
    
    p {
      font-size: 0.95rem;
      line-height: 1.6;
      max-width: 100%;
      word-wrap: break-word;
    }
    
    pre {
      max-width: 100%;
      overflow-x: auto;
      padding: 1rem;
      margin: 1rem -0.5rem;
      
      code {
        font-size: 0.85rem;
      }
    }
    
    blockquote {
      margin: 1rem 0;
      padding: 0.5rem 1rem;
    }
  }

  .blog-preview {
    padding: 1.5rem;
    
    h2 {
      font-size: 1.3rem;
    }
    
    p {
      font-size: 0.9rem;
    }
  }
  
  .blog-date {
    font-size: 0.8rem;
  }
  
  .tag {
    font-size: 0.75rem;
    padding: 0.25rem 0.6rem;
  }
}

.blog-article {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.3s ease-out forwards;
}

.blog-list {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.3s ease-out forwards;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
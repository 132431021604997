* {
  font-family: "Roboto", sans-serif;
}
html {
  display: flex;
  place-content: center;
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0px 5em 0px 5em;
  width: 100vw;
  overflow-x: hidden;
  &.dashboard-active {
    margin: 0;
  }
}

.nav-bar {
  padding: 1.5rem 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 72px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9998;
  margin: 0 5em;
  background: transparent;
  
  // Main blur container
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -5em;
    right: -5em;
    height: calc(100% + 20px);  // Reduced extended height
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    mask-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 1) 80%,    // Solid blur for most of navbar
      rgba(0, 0, 0, 0) 100%    // Fade out at bottom
    );
    -webkit-mask-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: -1;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  height: 30px;
  min-width: 60px;
  justify-content: flex-end;
  
  p {
    margin: 0;
    line-height: 30px;
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  // Create a wrapper for the nav links
  .nav-links-wrapper {
    display: flex;
    align-items: center;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;

    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}

a {
  cursor: pointer;
  margin-right: 1em;
  color: inherit;
  text-decoration: none;
}

.theme-logo {
  background: none;
  border: none;
  cursor: pointer;
  transition: 5s ease-in-out;
  outline: 0;
}

.hello-container {
  padding-top: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.hello-me {
  display: flex;
  flex-direction: column;
}

.work-with-me {
  background-color: #1351d8;
  width: 150px;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

#myMask2 > circle {
  cy: 2px !important;
}

.me-image-container {
  position: relative;
  -webkit-user-drag: none;
  object-fit: contain;
  max-width: 100vw;

  .me-image {
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    -webkit-user-drag: none;
    object-fit: contain;
    max-width: 100vw;
    opacity: 1; /* Reduced default opacity */

    &:hover {
      opacity: .6; /* Full opacity on hover */
    }
  }

  .hover-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    font-size: 20px;
    opacity: 0; /* Hidden by default */
    transition: opacity 0.2s ease-in-out;
    pointer-events: none; /* Ensures the text doesn't interfere with image clicking */
  }

  &:hover .hover-text {
    opacity: 1; /* Show text on hover */
  }
}

.my-work {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.my-project-container {
  display: grid;
  -webkit-box-align: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem;
}

.about-me {
  padding-top: 100px;
  text-align: center;
}

.my-toolbox {
  height: 400px;
  box-shadow: #00000069 0px 1px 6px 0px;
  border-radius: 25px;
  margin: 15px;
}

.tools-about {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.more-about {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  width: 33%;
}

.social-links {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-self: left;
}

.summary-head {
  font-size: 30px;
  text-align: left;
}

.my-summary {
  font-size: 1em;
  text-align: left;
  margin: 0;
}

.social-logo {
  height: 40px;
  padding-left: 5px;
}

#contact {
  margin-top: 20px;
}

.carousel-holder {
  width: 35%;
  height: 35%;
}

@media only screen and (max-width: 960px) {
  .my-project-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 850px) {
  body {
    margin: 0em 1em 0em 1em;
  }
  .hello-container {
    display: flex;
    flex-direction: column;
  }

  .hello-me {
    text-align: center;
    align-items: center;
    margin-bottom: 35px;
  }

  .my-toolbox {
    width: 350px;
    height: 300px;
  }

  .more-about {
    margin-top: 40px;
    width: unset;
  }

  .social-links {
    align-self: center;
  }

  .summary-head {
    text-align: center;
  }

  .my-summary {
    margin: 0;
  }

  .my-project-container {
    grid-template-columns: 1fr;
  }
  .carousel-holder {
    width: 80%;
    height: 35%;
  }

  .nav-bar {
    margin: 0 1em;
  }
}

@media only screen and (max-width: 1250px) {
  .tools-about {
    display: flex;
    flex-direction: column;
  }
}
/* Hide the default scrollbar */
::-webkit-scrollbar {
  display: none;
}

.projects-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  position: relative;

  h1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .view-scripts-btn {
    position: absolute;
    right: 0;
    background-color: #1351d8;
    color: white;
    padding: 0.8rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: .7rem;
    transition: background-color 0.2s;
    margin-right: 1rem;
    
    &:hover {
        background-color: #1976d2;
    }

    // Hide on mobile devices
    @media (max-width: 768px) {
        display: none;
    }
  }
}

.name-music-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  height: 30px;
}

.site-switcher {
  display: flex;
  gap: 2.22rem;
  height: 30px;
  align-items: center;
  a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    &:hover {
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
    }
    &:active {
      transform: scale(0.95);
    }
    
  }
}

@keyframes typeWriter {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink {
  50% { border-right-color: transparent; }
}

@keyframes blogTypeAnimation {
  0%, 15% { width: 0; }                /* empty */
  15.01%, 30% { width: 25%; }          /* b */
  30.01%, 45% { width: 50%; }          /* bl */
  45.01%, 60% { width: 75%; }          /* blo */
  60.01%, 100% { width: 100%; }        /* blog */
}

@keyframes devTagAnimation {
  0%, 10% { content: "<"; }
  10.01%, 20% { content: "</"; }
  20.01%, 30% { content: "</d"; }
  30.01%, 40% { content: "</de"; }
  40.01%, 50% { content: "</dev"; }
  50.01%, 100% { content: "</dev>"; }
}

.dev-tag {
  position: relative;
  cursor: pointer;
  display: inline-block;
  min-width: max-content;
  
  &:hover, &.active {
    color: transparent;
    
    &::before {
      color: #1351d8;
      position: absolute;
      content: "</dev>";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:hover::before {
    animation: devTagAnimation 1s steps(1) forwards;
  }

  &.active::before {
    animation: devTagAnimation 3s steps(6, end) infinite;
  }
}

.blog-text {
  position: relative;
  cursor: pointer;
  display: inline-block;
  min-width: max-content;
  letter-spacing: 1px;
  
  &:hover, &.active {
    color: transparent;
    
    &::before {
      color: #1351d8;
      content: attr(data-text);
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      overflow: hidden;
      white-space: nowrap;
      border-right: 2px solid #1351d8;
      height: 100%;
      box-sizing: border-box;
      letter-spacing: 1px;
    }
  }

  &:hover::before {
    animation: 
      blogTypeAnimation 1s steps(5, end) forwards,
      blink 0.75s step-end infinite;
  }

  &.active::before {
    animation: 
      blogTypeAnimation 3s steps(5, end) infinite,
      blink 0.75s step-end infinite;
  }
}

.page-container {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; // Better scroll on iOS
  padding-top: calc(72px + 1.5rem);
}

.content-section {
  position: absolute;
  width: 100%;
  will-change: transform, opacity;
  backface-visibility: hidden;
  
  &.dev-section {
    position: relative;
    transform: translateX(0);
    opacity: 1;
    
    &:not(.active) {
      transform: translateX(-100%);
      opacity: 0;
      pointer-events: none;
    }
  }
  
  &.blog-section {
    position: fixed;
    height: 100vh;
    overflow-y: hidden;
    padding-top: 100px;
    transform: translateX(100%);
    opacity: 0;
    top: 0;
    
    &.active {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (min-width: 769px) {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  
  @media (max-width: 768px) {
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }
}




@media (max-width: 768px) {
  .nav-bar {
    padding: 0.75rem 1rem;
    flex-direction: column;
    .name-music-container {
      flex-direction: column;
      align-items: center;
      gap: 0.25rem;
      margin-bottom: 0.5rem;
      
      p {
        font-size: 0.9rem;
        margin: 0;
      }
      
      .site-switcher {
        font-size: 1em;
        gap: 1.5rem;
      }
    }
    
    .nav-links {
      justify-content: center;
      
      .nav-links-wrapper {
        gap: 1rem;
        
        a p {
          font-size: 0.9rem;
          margin: 0;
        }
      }
      
      svg {
        position: absolute;
        right: 1rem;
        top: 25%;
        transform: translateY(-50%) rotate(40deg);
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
  }
}
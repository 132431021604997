canvas {
    display: block;
    width: 100vw;
    height: 100vh;
  }
  
  #speedometer {
    font-size: 18px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 8px;
}

#map {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 150px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  overflow: hidden;
}

#playerDot {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.exit-dashboard-btn {
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}


.start-engine-button {
  position: absolute;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
  right: 200px; /* Adjust the distance from the right as needed */
  z-index: 10; /* Ensure it's above other elements */
  padding: 10px 20px; /* Adjust padding to your liking */
  background-color: #007bff; /* Example color - adjust as needed */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Adjust font size as needed */
  box-shadow: 0 2px 4px rgba(0,0,0,0.3); /* Optional: adds a slight shadow for better visibility */
}

/* Adjustments for hover state for better user interaction feedback */
.start-engine-button:hover {
  background-color: #0056b3;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  font-size: 24px;
  font-family: Arial, sans-serif;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
